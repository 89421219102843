/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import * as React from 'react'
import { TrackingProvider } from './src/lib/context/TrackingContext'
import { I18nProvider } from './src/lib/context/i18n'
import { stripOutHomeForRootPath } from './src/lib/utils/translatedSlugs'
import { useScript } from './src/lib/utils/useScript'
import { formatPath } from './src/lib/utils/internalLinks'
import { StoryblokProvider } from './src/lib/context/StoryblokContext'
import posthog from 'posthog-js'
import LinkedInInsightTag from './src/components/LinkedInInsightTag'
import { useCookieBanner } from './src/features/CookieBanner/useCookieBanner'
import { CookieBannerContext } from './src/features/CookieBanner/CookieBannerContext'
import { readConsentFromClientStorage } from './src/features/CookieBanner/cookieService'
import * as Sentry from "@sentry/gatsby";


// Do not init posthog in the dev mode
const isNotLocalEnv = !window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')

// As key/token is shared with client side it is not really a secret so it is fine to be here
// Once we add multiple env for posthog if we want to do so we can move it to env file
if (isNotLocalEnv) {
  posthog.init('phc_w5uJGyVUVHUjWjXsYvVgJGdgV0aYIQNR9ayJmL2Vf8f', { api_host: 'https://eu.posthog.com' })
}

Sentry.init({
  dsn: "https://ee85e70ec49e73456da09b7a6a87bfbd@o4506201331400704.ingest.sentry.io/4506201338806272",
  integrations: [
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  enabled: isNotLocalEnv,
});

// TODO: move to some lib/common folder
const getCookieByName = name => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
  if (match) {
    return match[2]
  }
  return ''
}

// We are using hubspot ID to track users as it is generated from the moment user lands
const HUBSPOT_ID_COOKIE_KEY = 'hubspotutk'
const GOOGLE_ANALYTICS_COOKIE_KEY = '_gid'

export const onRouteUpdate = props => {
  const { prevLocation } = props

  if (isNotLocalEnv) {
    const hubspotId = getCookieByName(HUBSPOT_ID_COOKIE_KEY)
    const googleAnalyticsId = getCookieByName(GOOGLE_ANALYTICS_COOKIE_KEY)

    posthog.register({
      hubspotId: hubspotId,
      gid: googleAnalyticsId,
    })

    posthog.capture('$pageview')
  }

  if (window && window.dataLayer) {
    window.dataLayer.push({
      page_referrer: prevLocation ? prevLocation.pathname : 'NA',
    })
  }
}

export const wrapPageElement = ({ element, props }) => {
  const { pageContext } = props

  const defaultSlug = formatPath(stripOutHomeForRootPath(pageContext.defaultSlug))

  const consent = readConsentFromClientStorage()
  const cookieBanner = useCookieBanner(consent)

  useScript('//cdn-4.convertexperiments.com/js/10043310-10044619.js', 'convertTracking')

  return (
    <I18nProvider
      pageLanguage={pageContext.lang}
      translatedSlugs={pageContext.translated_slugs}
      defaultSlug={defaultSlug || 'default'}
    >
      <CookieBannerContext.Provider value={cookieBanner}>
        <StoryblokProvider>{element}</StoryblokProvider>
        {consent?.analytics && consent?.marketing && <LinkedInInsightTag />}
      </CookieBannerContext.Provider>
    </I18nProvider>
  )
}

export const wrapRootElement = ({ element }) => {
  return <TrackingProvider>{element}</TrackingProvider>
}

